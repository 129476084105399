<template>
  <div class="wrapper" id="payreg_add">
    <ctheader></ctheader>
    <div ref="payreg_add" class="payreg_add">
      <el-form ref="form" :model="form" label-width="220px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户编号：" prop="mer_no">
              <el-input v-model="form.mer_no" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户名称：" prop="mer_name">
              <el-input v-model="form.mer_name" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户类型：" prop="mer_type">
              <el-radio-group v-model="form.mer_type">
                <el-radio :label="1">个人商户</el-radio>
                <el-radio :label="2">企业</el-radio>
                <el-radio :label="3">个体工商户</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户种类：" prop="mer_sort">
              <el-radio-group v-model="form.mer_sort">
                <el-radio :label="1">普通类</el-radio>
                <el-radio :label="9">其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人/经营者姓名" prop="contact_name">
              <el-input
                v-model="form.contact_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人/经营者证件类型">
              <el-radio-group v-model="form.certificate_type">
                <el-radio label="I">身份证</el-radio>
                <el-radio label="P">护照</el-radio>
                <el-radio label="G">军官证</el-radio>
                <el-radio label="U">其他证件</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人/经营者证件编号">
              <el-input
                v-model="form.certificate_no"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个人工商户/企业名称">
              <el-input
                v-model="form.company_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="个体工商户/企业证件类型">
              <el-radio-group v-model="form.company_cert_type">
                <el-radio label="U">营业执照</el-radio>
                <el-radio label="Y">组织机构代码证</el-radio>
                <el-radio label="V">统一社会信用代码</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个体工商户/企业证件类型编号">
              <el-input
                v-model="form.company_cert_no"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行账户户名">
              <el-input
                v-model="form.account_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账户">
              <el-input
                v-model="form.mobile_phone"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行账户类型">
              <el-radio-group v-model="form.account_type">
                <el-radio label="1">借记卡</el-radio>
                <el-radio label="2">企业户</el-radio>
                <el-radio label="3">二类户</el-radio>
                <el-radio label="4">三类户</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际经营地址">
              <el-input v-model="form.address" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="form.remark" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户负责人联系电话">
              <el-input
                v-model="form.mer_mobile_phone"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-button type="primary" @click="onSubmit('form')" size="small">
          保存
        </el-button>
        <el-button size="small" @click="goBack">取消</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "@/modules/utils";
import APIs from "@/modules/APIs";
// import pagination from "../../components/pagination";
import { mapActions } from "vuex";
import router from "../../router";
import * as URL from "../../modules/URLs";

const actions = mapActions(["setAlert"]);

export default {
  components: { ctheader },
  data() {
    return {
      form: {
        action: "add",
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getPaymentInfo();
    }
  },
  methods: {
    ...actions,
    getPaymentInfo() {
      let $this = this;
      apiPost({
        url: APIs.PAYMENT,
        data: {
          action: "info.get",
          mer_id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            $this.form = res.data;
            $this.form.action = "update.info";
            // $this.setAlert({ msg: res.msg, type: true });
            // router.push(URL.PAYMENT);
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    onSubmit() {
      let $this = this;
      apiPost({
        url: APIs.PAYMENT,
        data: $this.form,
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            router.push(URL.PAYREG);
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    goBack() {
      router.push(URL.PAYREG);
    },
  },
};
</script>

<style lang="less">
.payreg_add {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
}
</style>
